@supports (-webkit-touch-callout: none) {

    html,
    body {
        overscroll-behavior: none;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;
    }
}

body {
    background-color: #fff;
    color: #dc0202;
}

a {
    color: #dc0202;
}

.page-container {
    max-width: 900px;
    min-height: 100vh;
    position: relative;
    padding-bottom: 270px;
}

/* header styling */
.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.fill img:nth-child(0) {
    flex-shrink: 0;
    min-width: 100%;
    max-height: 100%;
}

@media all and (max-width: 900px) {
    .fill {
        padding: 0px !important;
    }
}

@media all and (max-width: 550px) {
    .flyer {
        max-width: 300px !important;
    }
}

.flyer {
    max-width: 550px;
    display: block;
    margin: auto;
}

/* footer styling */
.footer {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    max-height: 270px;
}

/* select box */
.select {
    max-width: 200px;
    border-radius: 0px;
    border: 1px solid #dc0202;
    color: #dc0202;
}

.select:focus {
    border: 1px solid #dc0202;
    box-shadow: none;
}

/* input field */
.input {
    border: 1px solid #dc0202;
    color: #dc0202;
    border-radius: 0px;
}

.input:focus {
    border: 1px solid #dc0202;
    box-shadow: none;
}

/* button */
.button {
    background-color: #dc0202;
    color: #fff;
    border: 1px solid #dc0202;
    padding: 4px 40px;
    text-decoration: none;
    text-transform: uppercase;
}

.success {
    background-color: #319457 !important;
}

.success:hover {
    color: #319457 !important;
    background-color: #fff !important;
    border: 1px solid #319457 !important;

}

.button:hover {
    color: #dc0202;
    background-color: #fff;
    border: 1px solid #dc0202;
}

.button:disabled {
    background-color: #dc020292;
    color: #fff;
}

.center * {
    margin: auto;
    justify-content: center;
}